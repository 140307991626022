/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================



NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.card-step {
  .bs-stepper {
    background-color: transparent;
    box-shadow: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}
.userContactInfoListDiv {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  li {
    .userContactInfo {
      font-size: 12px;
      display: flex;
    }
  }
}

.comment-container {
  border: 1px solid rgb(154, 154, 154);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  textarea {
    border: 0;
    min-height: 150px;
    &:focus {
      box-shadow: unset !important;
    }
  }
  .add-Comment {
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.previus-commentSection {
  .previus-commentMainTitle {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    padding-block: 15px;
    margin-block-end: 20px;
    border-bottom: 1px solid rgb(236, 236, 236);
    .badge {
      font-size: 14px;
      margin-left: 8px;
    }
  }
  .previus-comment {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    .previus-comment-userInfo {
      .previus-comment-userName {
        font-size: 16px;
        display: flex;
        gap: 20px;
        color: black;
        span {
          position: relative;
          color: rgb(173, 173, 173);
          &::before {
            content: '.';
            font-size: 25px;
            line-height: 0;
            position: absolute;
            left: -12px;
            top: 16%;
          }
        }
      }
      .previus-comment-para {
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}

.invoice-container {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  .invoiceLogo {
    max-width: 200px;
  }
  .companyAddress {
    // max-width: 320px;
    margin-left: auto;
  }
}

.has_error_text {
  color: #ea5445;
  font-size: 12px;
}

.is-invalid {
  border: 1px solid #ea5445;
  border-radius: 0.357rem;
  outline: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 9px;
}

/*å®šä¹‰æ»šåŠ¨æ¡è½¨é“ å†…é˜´å½±+åœ†è§’*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  /* background-color: #fff; */
}

/*å®šä¹‰æ»‘å— å†…é˜´å½±+åœ†è§’*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ccc;
}

.sendEmails p {
  margin: 0;
  padding: 0;
}

.sendEmails {
  // background-color: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  // color: #000;
  margin-left: 10px;
}

.sub-text {
  color: #a3a3a3;
  font-size: 12px !important;
}

iframe {
  width: 100%;
  height: 100%; /* Optional: Set the height to take the full height of the container */
  border: none; /* Optional: Remove the border around the iframe */
  background-color: transparent; /* Set the background of the iframe to transparent */
}

iframe.disable-pointer-events {
  pointer-events: none;
}

.ribbon {
  width: 150px;
  height: 120px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #297fb969;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 195px;
  padding: 5px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  letter-spacing: 1.5px;
  font:
    700 14px/1 'Lato',
    sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 30px;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: 3px;
  top: 42px;
  transform: rotate(-45deg);
}
.invalid-feedback {
  display: block;
}

.filed_not_select_overlay {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    inset: -15px -21px -21px;
    background-color: #fff;
    opacity: 0.7;
    z-index: 1;
  }
}

.first_char {
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: #e9ebf3;
}

.sticky-modal-header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.popover-set-left .popover {
  left: -100px !important;
}

iframe {
  margin: 0px;
  padding: 0px;
  height: 100%;
  border: none;
}

iframe {
  display: block;
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}

.sticky_right {
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          &:last-child {
            position: sticky;
            right: 0;
            z-index: 99;
            background-color: #f3f2f7;
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow {
        .rdt_TableCell {
          &:last-child {
            position: sticky;
            right: 0;
            z-index: 99;
            background-color: #fff;
          }
        }
      }
    }
  }
}

.customer-mail-transaction-table {
  th {
    background-color: var(--primary) !important;
    color: white;
    padding: 10px;
  }
  td {
    padding: 10px;
  }
  tr:nth-child(odd) {
    background-color: #e0eff6;
  }
}

.text_overflow{
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
